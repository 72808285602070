<template>
    <div>
        <el-dialog :title=title :visible.sync="isShowSplit" width="480px" custom-class="resultDialog dataM"
            :before-close="handleClose">
            <div class="tip">
                <div class="itemClass">
                    <div class="split" v-for="(item, index) in list" :key="index">
                        <div class="text">
                            拆分{{ index + 1 }}:
                        </div>
                        <el-input v-model.number="item.num" />
                        <el-date-picker v-model="item.date" type="date" placeholder="请输入需求日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                    <div class="add" @click="addFn">+</div>
                    <div class="addInfo">*不填日期默认为原日期交付</div>
                </div>

                <div class="footer">
                    <el-button @click="handleClose" style="color:#B9B9B9">取消</el-button>
                    <el-button type="primary" @click="OK">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { splitOrder } from '@/api/orders.js'
export default {
    data() {
        return {
            list: [
                {
                    num: '',
                    date: ''
                },
                {
                    num: '',
                    date: ''
                }
            ]
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        isShowSplit: {
            type: Boolean,
            default: false
        },
        splitData: {
            type: Array
        }
    },
    methods: {
        handleClose() {
            this.$emit('closeSplit')
            this.list = [
                {
                    num: '',
                    date: ''
                },
                {
                    num: '',
                    date: ''
                }
            ]
        },
        async OK() {
            if (this.list.find(v => v.num === '')) {
                this.$message.warning('请输入拆分数量！')
                return
            }
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].num === 0) {
                    this.$message.warning('拆分数量不能为0！')
                    return
                } else if (this.list[i].num < 0) {
                    this.$message.warning('拆分数量不能为负数！')
                    return
                } else if (typeof this.list[i].num !== 'number') {
                    this.$message.warning('输入有误！')
                    return
                } else if (String(this.list[i].num).includes('.')) {
                    this.$message.warning('拆分数量只能输入整数！')
                    return
                }
                if (this.list[i].date === '') {
                    this.list[i].date = this.splitData[0].date
                }
            }
            let tempList = []
            for (let i = 0; i < this.list.length; i++) {
                tempList.push({
                    orderQuantity: this.list[i].num,
                    orderCompletionDate: this.list[i].date
                })
            }
            let params = {
                id: this.splitData[0].id,
                orderSplitInfos: tempList
            }
            const res = await splitOrder(params)
            // console.log('当前打印:拆分结果', res)
            if (res.code === 0) {
                this.$message.success('工单拆分成功！')
            } else {
                this.$message.warning(res.msg)
            }
            this.$emit('splitOk')
            this.list = [
                {
                    num: '',
                    date: ''
                },
                {
                    num: '',
                    date: ''
                }
            ]
        },
        addFn() {
            this.list.push({
                num: '',
                date: ''
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.resultDialog {
    img {
        width: 70px;
        height: 70px;
    }

    .tip {
        padding: 3px 7px 3px 7px;

        div {
            line-height: 40px;
            color: #B9B9B9;
        }

        .title {
            font-size: 16px;
            text-align: left;
            color: #E8E8E8;
        }

        .el-button {
            color: #fff;
            width: 80px;
            height: 40px;
        }
    }
}

.footer {
    text-align: right;
    margin-top: 12px;
}

::v-deep .el-dialog__header {
    text-align: left;
    padding-top: 25px !important;
    padding-left: 25px !important;

    .el-dialog__title {
        font-size: 22px;
        // margin-left: 15px;
    }
}

::v-deep .el-date-editor {
    .el-input__prefix {
        right: 0px !important;
        left: auto;
        margin-right: 5px;
    }

    .el-input__inner {
        padding: 0 15px;
    }
}

::v-deep .el-input {
    width: 180px;
}

.split {
    display: flex;
    gap: 10px;
    height: 40px;
    margin-bottom: 15px;
}

::v-deep .el-date-editor {
    .el-input__prefix {
        right: 0px !important;
        left: auto;
        margin-right: 5px;
    }

    .el-input__inner {
        padding: 0 15px;
    }
}

.text {
    width: 55px;
    font-size: 15px;
    margin-right: -12px;
    text-align: left;
}

.add {
    width: 180px;
    height: 40px;
    border-radius: 5px;
    border: 1px dotted #4F4F4F;
    margin-left: 52px;
    font-size: 25px;
    font-weight: 300;
    color: #727272 !important;
    cursor: pointer;
}

.addInfo {
    margin-left: 52px;
    font-size: 14px;
    color: #8A8A8A;
    text-align: left;
    margin-bottom: 20px;
}
</style>